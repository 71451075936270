import React, { useState, useEffect, useRef } from 'react';
import { Chart, ChartEvent, registerables } from 'chart.js/auto';

import * as d3 from 'd3';
import { theme } from '../../constants/theme';
import { AudioSamplesFromServerState } from '../../types/AudioSampleState';
import { VariationState } from '../../types/VariationState';
import {
  getNewLatentVectorWithIncreasedDifferenciesFromTwoLatentVector,
  getNewlatentVectorFromAValueWithVectorVariationPlan
} from '../../tools/vectorManipulations';

Chart.register(...registerables);

interface Props {
  audioSamplesFromServerState: AudioSamplesFromServerState;
  onDragEnd: (index: number, value: number) => void;
  variationState: VariationState;
  color: string;
  lowOpacityColor: string;
}

const RadarChart: React.FC<Props> = ({
  audioSamplesFromServerState,
  onDragEnd,
  variationState,
  color,
  lowOpacityColor
}) => {
  const chartContainer = useRef<HTMLCanvasElement | null>(null);
  const chartInstance = useRef<any>(null);
  const wrapperContainerRef = useRef<any>(null);

  const [data, setData] = useState<number[]>([]);
  const [originalDataGenerated, setOriginalDataGenerated] = useState<number[]>([]);

  const [nearestPoint, setNearestPoint] = useState<any>(null);

  const [latentVectorDifferenceIncreaseScale] = useState<number>(1);

  const [wrapperContainerDimensions, setWrapperContainerDimensions] = useState<{
    x: number;
    y: number;
  }>({
    x: 0,
    y: 0
  });

  useEffect(() => {
    if (wrapperContainerRef.current) {
      setWrapperContainerDimensions({
        x: wrapperContainerRef.current.clientWidth,
        y: wrapperContainerRef.current.clientHeight
      });
    }
  }, [wrapperContainerRef.current]);

  function roundValue(value: any, pos: any) {
    if (!isNaN(pos)) {
      return Math.round(value * Math.pow(10, pos)) / Math.pow(10, pos);
    }
    return value;
  }

  function calcRadar(e: any, chartInstance: any) {
    const x = e.x;
    const y = e.y;
    let v;

    let rScale = chartInstance.scales.r;

    let d = Math.sqrt(Math.pow(x - wrapperContainerDimensions.x / 2, 2) + Math.pow(y - 140, 2));
    let scalingFactor = rScale.drawingArea / (rScale.max - rScale.min);

    v = rScale.min + d / scalingFactor;
    v = roundValue(v, 5);

    v = v > chartInstance.scales.r.max ? chartInstance.scales.r.max : v;
    v = v < chartInstance.scales.r.min ? chartInstance.scales.r.min : v;

    return v;
  }

  useEffect(() => {
    if (audioSamplesFromServerState.currentSampleFetched.latentVector) {
      if (audioSamplesFromServerState.currentSampleFetched.originalSample) {
        const vector = getNewLatentVectorWithIncreasedDifferenciesFromTwoLatentVector(
          audioSamplesFromServerState.currentSampleFetched.originalSample.latentVector,
          audioSamplesFromServerState.currentSampleFetched.latentVector,
          latentVectorDifferenceIncreaseScale
        );
        setData(vector);
      } else {
        setData(audioSamplesFromServerState.currentSampleFetched.latentVector);
      }
    }
    if (audioSamplesFromServerState.currentSampleFetched.originalSample) {
      setOriginalDataGenerated(
        audioSamplesFromServerState.currentSampleFetched.originalSample.latentVector
      );
    } else {
      setOriginalDataGenerated(audioSamplesFromServerState.currentSampleFetched.latentVector);
    }
  }, [
    audioSamplesFromServerState.currentSampleFetched.latentVector,
    audioSamplesFromServerState.samplesHistory
  ]);

  useEffect(() => {
    if (variationState.variationValue) {
      const newlatentVector = getNewlatentVectorFromAValueWithVectorVariationPlan(
        audioSamplesFromServerState.currentSampleFetched.originalSample
          ? audioSamplesFromServerState.currentSampleFetched.originalSample.latentVector
          : audioSamplesFromServerState.currentSampleFetched.latentVector,
        variationState.variationPlan,
        variationState.variationValue.x
      );
      const increasedDifferenciesVector =
        getNewLatentVectorWithIncreasedDifferenciesFromTwoLatentVector(
          audioSamplesFromServerState.currentSampleFetched.originalSample
            ? audioSamplesFromServerState.currentSampleFetched.originalSample.latentVector
            : audioSamplesFromServerState.currentSampleFetched.latentVector,
          newlatentVector,
          latentVectorDifferenceIncreaseScale
        );
      setData(increasedDifferenciesVector);
    }
  }, [variationState.variationValue.x]);

  const dragEndCallback = (event: Event, index: number) => {
    const value = calcRadar(event, chartInstance.current);
    onDragEnd(index, value);
  };

  useEffect(() => {
    if (chartContainer && chartContainer.current) {
      if (chartInstance.current) {
        chartInstance.current.data.datasets[0].data = data;
        chartInstance.current.data.datasets[1].data = originalDataGenerated;
        chartInstance.current.data.datasets[0].backgroundColor = lowOpacityColor;
        chartInstance.current.data.datasets[0].borderColor = color;
        chartInstance.current.data.datasets[1].backgroundColor = lowOpacityColor;
        chartInstance.current.data.datasets[1].borderColor = lowOpacityColor;
        chartInstance.current.update();
      } else {
        chartInstance.current = new Chart(chartContainer.current, {
          type: 'radar',
          data: {
            labels: Array.from({ length: 128 }, (_, index) => index + 1),
            datasets: [
              {
                data: data,
                backgroundColor: lowOpacityColor,
                borderColor: color,
                borderWidth: 1
              },
              {
                data: originalDataGenerated,
                backgroundColor: lowOpacityColor,
                borderColor: lowOpacityColor,
                borderWidth: 1
              }
            ]
          },
          options: {
            responsive: true,
            interaction: {
              intersect: false,
              mode: 'nearest'
            },
            scales: {
              r: {
                angleLines: {
                  display: false,
                  lineWidth: 1,
                  color: 'rgba(255, 255, 255, 0.3)'
                },
                min: -5,
                max: 5,
                ticks: {
                  display: false
                },
                display: false
              }
            },
            plugins: {
              legend: {
                display: false
              },
              tooltip: {
                enabled: false
              }
            },
            elements: {
              point: {
                radius: 0
              }
            },
            animation: {
              duration: 400
            },
            onHover(event: ChartEvent) {
              let nearestPoints = chartInstance.current.getElementsAtEventForMode(
                event,
                'nearest',
                {
                  intersect: false
                },
                true
              );
              if (nearestPoints) {
                setNearestPoint(nearestPoints);
              }
            }
          }
        });
      }
    }
  }, [chartContainer, data, color]);

  useEffect(() => {
    if (chartInstance.current && audioSamplesFromServerState.currentSampleFetched.latentVector) {
      if (nearestPoint && nearestPoint[0]) {
        const canvas = chartInstance.current.canvas;

        const datasetIndex = 0; // to avoid dragging points from the originalData
        const pointIndex = nearestPoint[0].index;

        const drag = d3
          .drag()
          .on('drag', (event: any) => {
            chartInstance.current.data.datasets[datasetIndex].data[pointIndex] = calcRadar(
              event,
              chartInstance.current
            );
            // pointINdex = dimension (parmis 128)
            // calcRadar(event, chartInstance.current) = la nouvelle valeur de la dimension

            chartInstance.current.update();
          })
          .on('end', (event: any) => {
            dragEndCallback(event, pointIndex);
          });

        // Attach the drag behavior to the canvas element
        d3.select(canvas).call(drag);
      }
    }
  }, [nearestPoint]);

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '10px',
        backgroundColor: theme.palette.black
      }}
      ref={wrapperContainerRef}>
      <canvas ref={chartContainer} />
    </div>
  );
};

export default RadarChart;
