import { FC } from 'react';
import './OneDVariationContainer.css';
import SimpleSVGComponent from '../UI/SimpleSVGComponent';
import changeIcon from '../../assets/change-icon.svg';
import RotarySlider from '../RotarySliderContainer/RotarySlider';
import { VariationState } from '../../types/VariationState';

interface OneDVariationContainerProps {
  value: number;
  onOneDVariationSliderChange: (x: number) => void;
  onClickOnDirectionVariationButton: () => void;
  setVariationState: (value: React.SetStateAction<VariationState>) => void;
}

const OneDVariationContainer: FC<OneDVariationContainerProps> = ({
  value,
  onOneDVariationSliderChange,
  onClickOnDirectionVariationButton,
  setVariationState
}) => {
  const onSliderChange = (newValue: number) => {
    setVariationState((data) => {
      return { ...data, variationValue: { x: newValue, y: 0 } };
    });
  };

  const onSliderChangeEnd = (newValue: number) => {
    onOneDVariationSliderChange(newValue);
  };

  return (
    <div className="one-d-variation-border-container">
      <div className="one-d-variation-main-container">
        <div className="one-d-variation-bottom-container">
          <div className="one-d-variation-type-container">Variation</div>
          <div className="one-d-variation-bottom-left-container">
            <RotarySlider
              value={value}
              onChange={onSliderChange}
              onChangeEnd={onSliderChangeEnd}
              type="Variation"
              size={0.95}
              showGauge={false}
              showHand={false}
            />
            <div className="one-d-variation-bottom-left-container-intensity-tag">{'Intensity'}</div>
          </div>
          <div className="one-d-variation-bottom-right-container">
            <div className="one-d-variation-squared-button-border">
              <div
                onClick={() => onClickOnDirectionVariationButton()}
                className="one-d-variation-squared-button">
                <SimpleSVGComponent
                  width="2rem"
                  height="auto"
                  alt="change-icon"
                  icon={changeIcon}
                />
                NEW
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OneDVariationContainer;
