import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { WorkSpaceBoxState } from '../../types/WorkSpace';
import './WorkSpaceBox.css';
import { theme } from '../../constants/theme';
import SimpleSVGComponent from '../UI/SimpleSVGComponent';
import logo from '../../assets/Logo_DrumGan.svg';
import { playAudio, playAudioWithNewAudioPlayer } from '../../tools/playAudio';
import { useKeyPress } from '../../hooks/UseKeyPress';
import { normalizeSampleName } from '../../tools/stringsTools';
import { motion } from 'framer-motion';
import { isMobile } from 'react-device-detect';

interface WorkSpaceBoxProps {
  workSpaceBoxState: WorkSpaceBoxState;
  onClick: (index: number) => void;
  keyBoardLanguage: 'English' | 'French';
}

const WorkSpaceBox: FC<WorkSpaceBoxProps> = ({ workSpaceBoxState, onClick, keyBoardLanguage }) => {
  const audioRef = useRef<any>();
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [isHover, setIsHover] = useState<boolean>(false);
  const [textFontSize, setTextFontSize] = useState<string>('15');

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsPlaying(false);
    }, 60);
    return () => clearTimeout(timer);
  }, [isPlaying]);

  const onKeyPressed = (event: any) => {
    if (workSpaceBoxState.sample) {
      if (keyBoardLanguage === 'English' && workSpaceBoxState.englishKey === event.key) {
        const url = URL.createObjectURL(workSpaceBoxState.sample.blob);
        // playAudio(url, audioRef);
        playAudioWithNewAudioPlayer(url);
        setIsPlaying(true);
      }
      if (keyBoardLanguage === 'French' && workSpaceBoxState.frenchKey === event.key) {
        const url = URL.createObjectURL(workSpaceBoxState.sample.blob);
        playAudio(url, audioRef);
        setIsPlaying(true);
      }
    }
  };
  const item = {
    hidden: { opacity: 0 },
    show: { opacity: 1 }
  };
  useKeyPress(['a', 'z', 'e', 'r', 'q', 's', 'd', 'f', 'q', 'w'], onKeyPressed);
  return (
    <motion.div
      variants={item}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      className="workspace-box-main-container">
      <audio ref={audioRef} />
      {(
        <div
          className="workspace-box-top-container"
          style={{
            backgroundColor:
              workSpaceBoxState.isSelected || isHover
                ? workSpaceBoxState.color
                : theme.palette.darkGrey
          }}>
          <span
            style={{
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              fontSize: textFontSize
            }}>
            {workSpaceBoxState.sample?.filename
              ? normalizeSampleName(workSpaceBoxState.sample.filename, false, true)
              : `workspace ${workSpaceBoxState.index + 1}`}
          </span>
        </div>
      )}
      <div
        className="workspace-box-bottom-container"
        onClick={() => onClick(workSpaceBoxState.index)}
        style={{
          backgroundColor:
            isPlaying || isHover || workSpaceBoxState.isSelected
              ? theme.palette.darkGrey
              : theme.palette.black
        }}>
        <div className="workspace-box-letter-container">
          <div className="workspace-box-logo-container">
            <SimpleSVGComponent height="60%" width="auto" alt="logo" icon={logo} />
          </div>
          <div className="workspace-box-digit-container">
            {isMobile
              ? workSpaceBoxState.index + 1
              : keyBoardLanguage === 'English'
              ? workSpaceBoxState.englishKey
              : workSpaceBoxState.frenchKey}
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default WorkSpaceBox;
