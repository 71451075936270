import { FC, useCallback, useEffect, useState } from 'react';
import { AudioSamplesFromServerState } from '../../types/AudioSampleState';
import './WaveFormContainer.css';
import WaveForm from './WaveForm';
import { normalizeSampleName } from '../../tools/stringsTools';
import downloadIcon from '../../assets/download-icon.svg';
import { WorkSpaceBoxState } from '../../types/WorkSpace';
import SimpleSVGComponent from '../UI/SimpleSVGComponent';

interface WaveFormContainerProps {
  audioSampleState: AudioSamplesFromServerState;
  workspaceBox: WorkSpaceBoxState;
}

const WaveFormContainer: FC<WaveFormContainerProps> = ({ audioSampleState, workspaceBox }) => {
  const [audioBuffer, setAudioBuffer] = useState<any>();

  useEffect(() => {
    if (audioSampleState.currentSampleFetched.blob) {
      const audioContext = new AudioContext();
      audioSampleState.currentSampleFetched.blob
        .arrayBuffer()
        .then((array: any) =>
          audioContext.decodeAudioData(array, (newArray) => setAudioBuffer(newArray))
        );
    }
  }, [audioSampleState.currentSampleFetched.blob]);

  const onDownloadSample = useCallback(() => {
    if (audioSampleState.currentSampleFetched) {
      const url = URL.createObjectURL(audioSampleState.currentSampleFetched.blob);
      let a = document.createElement('a');
      a.href = url;
      a.download = audioSampleState.currentSampleFetched.filename + '.wav';
      a.click();
      URL.revokeObjectURL(url);
    }
  }, [audioSampleState.currentSampleFetched]);

  return (
    <div className="waveform-container-border-container">
      <div className="waveform-container-main-container">
        <div className="waveform-container-waveform-boundaries">
          <WaveForm
            color={workspaceBox.color}
            width={1000}
            height={1000}
            audioBuffer={audioBuffer}
          />
        </div>
        <div className="waveform-container-sample-name-background">
          <div className="waveform-container-sample-name-grey-background">
            {normalizeSampleName(audioSampleState.currentSampleFetched.filename, false, true)}
            <div className="waveform-container-download-button" onClick={() => onDownloadSample()}>
              <SimpleSVGComponent icon={downloadIcon} alt="download-icon" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WaveFormContainer;
