import React, { useEffect, useState } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';

import Header from './components/Header/Header';

import { theme } from './constants/theme';

import Footer from './components/Footer/Footer';
import MainContainer from './components/MainContainer/MainContainer';
import './App.css';
import { VariationState, variationInititalState } from './types/VariationState';
import { KSCSliderState, KSCSliderStateInitialValue } from './types/KSCSliderState';
import { AudioSample, AudioSamplesFromServerState } from './types/AudioSampleState';
import { WorkSpacesInitialState, WorkSpacesState } from './types/WorkSpace';
import LegalDialog, { CheckLegal } from './components/legaldialog/legalDialog';

const queryClient = new QueryClient();

function App() {
  const [variationState, setVariationState] = useState<VariationState>(variationInititalState);
  const [KSCSliderState, setKSCSliderState] = useState<KSCSliderState>(KSCSliderStateInitialValue);
  const [initialAudioSample] = useState<AudioSample>({
    filename: '',
    kickValue: KSCSliderState.kickValue,
    snareValue: KSCSliderState.snareValue,
    cymbalValue: KSCSliderState.cymbalValue,
    variationValue: variationState.variationValue,
    latentVector: [],
    currentVariationPlan: variationState.variationPlan,
    isOriginal: true,
    originalSample: null
  });
  const [audioSamplesFromServerState, setAudioSamplesFromServerState] =
    useState<AudioSamplesFromServerState>({
      currentSampleFetched: initialAudioSample,
      isFetching: false,
      isError: false,
      errorMessage: '',
      isSuccess: false,
      isLastFetchedSampleAVariation: false,
      samplesHistory: [],
      lastOriginalSample: initialAudioSample,
      currentHistoryIndex: 0
    });

  const [workSpacesState, setWorkSpacesState] = useState<WorkSpacesState>(WorkSpacesInitialState);
  const [agreed, setAgreed] = useState<boolean>(false);

  useEffect(() => {
    setAgreed(CheckLegal());
  }, [agreed]);

  return (
    <QueryClientProvider client={queryClient}>
      <div className="App disable-text-selection" style={{ backgroundColor: theme.palette.black }}>
        <Header audioSamplesState={audioSamplesFromServerState} />
        {!agreed ? (
          <LegalDialog onChange={() => setAgreed(CheckLegal())} />
        ) : (
          <MainContainer
            variationState={variationState}
            setVariationState={setVariationState}
            KSCSliderState={KSCSliderState}
            setKSCSliderState={setKSCSliderState}
            audioSamplesFromServerState={audioSamplesFromServerState}
            setAudioSamplesFromServerState={setAudioSamplesFromServerState}
            workSpacesState={workSpacesState}
            setWorkSpacesState={setWorkSpacesState}
          />
        )}
        <Footer />
      </div>
    </QueryClientProvider>
  );
}

export default App;
