import { FC } from 'react';
import './GenerateButton.css';
import SimpleSVGComponent from '../UI/SimpleSVGComponent';
import diceLogo from '../../assets/dice.svg';
import SquaredButton from './SquaredButton';
import circleBackIcon from '../../assets/circle-back-icon.svg';
import { isMobile, useMobileOrientation } from 'react-device-detect';

interface GenerateButtonProps {
  onClick: () => void;
  onClickOnSampleHistoryButtons: (mode: string) => void;
}

const GenerateButton: FC<GenerateButtonProps> = ({ onClick, onClickOnSampleHistoryButtons }) => {
  const { isLandscape } = useMobileOrientation();

  return (
    <div className="generate-button-border-container">
      {isMobile && !isLandscape && <div className="generate-button-right-side">
        <SquaredButton
          icon={circleBackIcon}
          alt="back"
          onClick={() => onClickOnSampleHistoryButtons('backward')}
          orientation={isLandscape ? 'right' : isMobile ? 'top' : 'right'}
          iconWidth="1.5rem"
        />
        <SquaredButton
          icon={circleBackIcon}
          alt="back"
          onClick={() => onClickOnSampleHistoryButtons('forward')}
          orientation={isLandscape ? 'right' : (isMobile ? 'top' : 'right')}
          iconWidth="1.5rem"
          rotateIcon="90deg"
        />
        {!isMobile && (
          <>
            <div className="black-square"></div>
            <div className="black-square"></div>
          </>
        )}
      </div>}
      <div className="generate-button-left-side">
        <div onClick={onClick} className="generate-button-main-container">
          <div className="generate-button-background-border">
            <div className="generate-button-background-container">
              <SimpleSVGComponent width="3rem" alt="dice-logo" icon={diceLogo} height="auto" />
              Generate
            </div>
          </div>
        </div>
      </div>
      {(!isMobile || isLandscape) && <div className="generate-button-right-side">
        <SquaredButton
          icon={circleBackIcon}
          alt="back"
          onClick={() => onClickOnSampleHistoryButtons('backward')}
          orientation={isLandscape ? 'right' : isMobile ? 'top' : 'right'}
          iconWidth="1.5rem"
        />
        <SquaredButton
          icon={circleBackIcon}
          alt="back"
          onClick={() => onClickOnSampleHistoryButtons('forward')}
          orientation={isLandscape ? 'right' : (isMobile ? 'top' : 'right')}
          iconWidth="1.5rem"
          rotateIcon="90deg"
        />
        {!isMobile && (
          <>
            <div className="black-square"></div>
            <div className="black-square"></div>
          </>
        )}
      </div>}
    </div>
  );
};

export default GenerateButton;
