import React, { FC, useEffect, useRef, useState } from 'react';

import './HorizontalSlider.css';
import { Slider } from '@mui/material';

interface HorizontalSliderProps {
  value: number;
  onChange: (value: number) => void;
  type: 'Kick' | 'Snare' | 'Cymbal' | 'Variation';

  size: number;

  showGauge: boolean;
  showHand: boolean;
  onChangeEnd: (value: number) => void;
}

const HorizontalSlider: FC<HorizontalSliderProps> = ({
  value,
  onChange,
  type,
  size,
  showGauge,
  showHand,
  onChangeEnd
}) => {
  const [localValue, setLocalValue] = useState<number>(value);
  const [gaugeDots, setGaugeDots] = useState<React.JSX.Element[]>([]);

  const HorizontalSliderContainerRef = useRef<HTMLDivElement>(null);
  const [containerHeight, setContainerHeight] = useState<number>(0);

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  useEffect(() => {
    if (HorizontalSliderContainerRef.current) {
      const containerHeight = HorizontalSliderContainerRef.current.offsetWidth;
      setContainerHeight(containerHeight);
    }
  }, []);

  const onDragEnd = (e: any) => {
    onChangeEnd(e.target.value / 100);
  };
  const onChangeSlider = (e: any) => {
    setLocalValue(e.target.value);
    onChange(e.target.value);
  };

  useEffect(() => {
    const dotsToFill = (localValue / 2) * 8;
    const dotsOffsetToCenterThem = -55;

    const dots = Array(8)
      .fill(null)
      .map((_, index) => (
        <svg
          key={`rotary-slider-gauge-dot-${index}`}
          style={{
            transform: `translateY(${index * 10 * 1.6 + dotsOffsetToCenterThem}px)`,
            fill: index < 8 - dotsToFill ? '#424242' : `rgba(255, 255, 255, 1)`,
            width: '10px',
            height: '10px',
            position: 'absolute'
          }}
          viewBox="0 0 6 6">
          <circle cx="2.71966" cy="3.31009" r="2.5" transform="rotate(4.46501 2.71966 3.31009)" />
        </svg>
      ));
    setGaugeDots(dots);
  }, [localValue]);

  return (
    <div
      id={`horizontal-slider-${type}`}
      className="horizontal-slider-container"
      ref={HorizontalSliderContainerRef}>
      <div className="horizontal-slider-gauge-container">
        {gaugeDots}
      </div>
      <div className="horizontal-slider-input-container">
        <Slider
          sx={{
            '& input[type="range"]': {
              WebkitAppearance: 'slider-horizontal'
            },
            '& .MuiSlider-track': {
              color: '#1A191E',
              boxShadow: 0
            },
            '& .MuiSlider-rail': {
              color: '#1A191E',
              height: '4px',
              borderRadius: '10px',
              boxShadow: 0
            },
            '& .MuiSlider-thumb': {
              color: '#151518',
              width: '25px',
              height: '15px',
              borderRadius: '3px',
              boxShadow: 0
            }
          }}
          style={{
            width: `${containerHeight * 0.75}px`
          }}
          orientation="horizontal"
          defaultValue={localValue}
          value={localValue}
          min={0}
          step={0.01}
          max={2}
          onChange={(event: any, value: any) => {
            onChange(value);
          }}
          onChangeCommitted={(event: any, value: any) => {
            onChangeEnd(value);
          }}
        />
      </div>
    </div>
  );
};

export default HorizontalSlider;
