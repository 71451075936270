import React, { FC, useRef, useState } from 'react';

interface DistanceTrackerProps {
  localValue: number;
  setIsSliding: (value: boolean) => void;
  sliderAcceleration: number;
  onChange: (value: number) => void;
  onChangeEnd: (value: number) => void;
}

const DistanceTracker: FC<DistanceTrackerProps> = ({
  localValue,
  setIsSliding,
  sliderAcceleration,
  onChange,
  onChangeEnd,
}) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [startY, setStartY] = useState<number | null>(null);

  const handleStart = (event: React.DragEvent<HTMLDivElement>) => {
    setStartY(event.clientY);
    setIsSliding(true);
  };

  const handleEnd = () => {
    setStartY(null);
    setIsSliding(false);
    onChangeEnd(localValue);
  };

  const handleMove = (event: React.DragEvent<HTMLDivElement>) => {
    if (startY !== null) {
      const movement = event.clientY - startY;
      let sliderValue = localValue - movement * sliderAcceleration;
      sliderValue = Math.max(0, Math.min(2, sliderValue));
      setStartY(event.clientY);
      onChange(sliderValue);
    }
  };

  return (
    <div
      draggable={true}
      ref={containerRef}
      style={{ width: '100%', height: '100%' }}
      onDragStart={(event) => {
        handleStart(event);
      }}
      onDragOver={(event) => {
        handleMove(event);
      }}
      onDragEnd={() => {
        handleEnd();
      }}
    />
  );
};

export default DistanceTracker;
