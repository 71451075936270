import { FC } from 'react';
import { useMobileOrientation } from 'react-device-detect';
import './MobilePortraitContainer.css';
import MobilePortraitContainer from './MobilePortraitContainer';
import MobileLandscapeContainer from './MobileLandscapeContainer';
import { AudioSamplesFromServerState } from '../../types/AudioSampleState';
import { WorkSpaceBoxState, WorkSpacesState } from '../../types/WorkSpace';
import { VariationState } from '../../types/VariationState';
import { KSCSliderState } from '../../types/KSCSliderState';

interface MobileMainContainerProps {
  audioSampleState: AudioSamplesFromServerState;
  workspaceBox: WorkSpaceBoxState;
  onRadarChartDragEnd: (index: number, value: number) => void;
  variationState: VariationState;
  onClick: () => void;
  onClickOnSampleHistoryButtons: (mode: string) => void;
  workSpacesState: WorkSpacesState;
  onClickOnWorkSPaceBox: (index: number) => void;
  setWorkSpaceState: (value: React.SetStateAction<WorkSpacesState>) => void;
  onChange: (value: number, type: 'Kick' | 'Snare' | 'Cymbal' | 'Variation', setTheRestToZero: boolean) => void;
  KSCSliderState: KSCSliderState;
  setKSCSliderState: (value: React.SetStateAction<KSCSliderState>) => void;
  value: number;
  onOneDVariationSliderChange: (x: number) => void;
  onClickOnDirectionVariationButton: () => void;
  setVariationState: (value: React.SetStateAction<VariationState>) => void;
  onDroppedAnAudioFile: (file: any) => void;
}

const MobileMainContainer: FC<MobileMainContainerProps> = ({
  audioSampleState,
  workspaceBox,
  onRadarChartDragEnd,
  variationState,
  onClick,
  onClickOnSampleHistoryButtons,
  workSpacesState,
  onClickOnWorkSPaceBox,
  setWorkSpaceState,
  onChange,
  KSCSliderState,
  setKSCSliderState,
  onOneDVariationSliderChange,
  onClickOnDirectionVariationButton,
  setVariationState,
  onDroppedAnAudioFile
}) => {
  const { isLandscape } = useMobileOrientation();

  return (
    <>
      {isLandscape && (
        <MobileLandscapeContainer
          audioSamplesFromServerState={audioSampleState}
          currentWorkspaceSelected={workspaceBox}
          onRadarChartDragEnd={onRadarChartDragEnd}
          variationState={variationState}
          workspaceBox={workspaceBox}
          onClickOnGenerateButton={onClick}
          onClickOnSampleHistoryButtons={onClickOnSampleHistoryButtons}
          workSpacesState={workSpacesState}
          onClickOnWorkSPaceBox={onClickOnWorkSPaceBox}
          setWorkSpaceState={setWorkSpaceState}
          onChange={onChange}
          KSCSliderState={KSCSliderState}
          setKSCSliderState={setKSCSliderState}
          onOneDVariationSliderChange={onOneDVariationSliderChange}
          onClickOnDirectionVariationButton={onClickOnDirectionVariationButton}
          setVariationState={setVariationState}
          onDroppedAnAudioFile={onDroppedAnAudioFile}
        />
      )}

      {!isLandscape && (
        <MobilePortraitContainer
          audioSamplesFromServerState={audioSampleState}
          currentWorkspaceSelected={workspaceBox}
          onRadarChartDragEnd={onRadarChartDragEnd}
          variationState={variationState}
          workspaceBox={workspaceBox}
          onClickOnGenerateButton={onClick}
          onClickOnSampleHistoryButtons={onClickOnSampleHistoryButtons}
          workSpacesState={workSpacesState}
          onClickOnWorkSPaceBox={onClickOnWorkSPaceBox}
          setWorkSpaceState={setWorkSpaceState}
          onChange={onChange}
          KSCSliderState={KSCSliderState}
          setKSCSliderState={setKSCSliderState}
          onOneDVariationSliderChange={onOneDVariationSliderChange}
          onClickOnDirectionVariationButton={onClickOnDirectionVariationButton}
          setVariationState={setVariationState}
        />
      )}
    </>
  );
};

export default MobileMainContainer;
