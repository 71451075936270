import { FC } from "react";
import "./RoundedButtonWithIcon.css";

interface RoundedButtonWithLogoProps {
  icon: any;
  onClick: () => void;
  logoWidth?: string;
  disabled: boolean;
  borderRadius?: string;
}

const RoundedButtonWithLogo: FC<RoundedButtonWithLogoProps> = ({
  icon,
  onClick,
  logoWidth,
  disabled,
  borderRadius,
}) => {
  return (
    <div
      onClick={onClick}
      className={
        disabled === true
          ? "rounded-button-container-disabled rounded-button-container"
          : "rounded-button-container"
      }
      style={{ borderRadius: borderRadius ? borderRadius : "50%" }}
    >
      <img
        style={{ width: logoWidth ? logoWidth : "60%" }}
        src={icon}
        alt="icon"
      />
    </div>
  );
};

export default RoundedButtonWithLogo;
