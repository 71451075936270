import { FC, useMemo } from 'react';
import SimpleSVGComponent from '../UI/SimpleSVGComponent';
import './SquaredButton.css';

interface SquaredButtonProps {
  onClick: () => void;
  icon: any;
  alt: string;
  orientation: 'top' | 'bottom' | 'left' | 'right';
  iconWidth?: string;
  iconHeight?: string;
  rotateIcon?: string;
}

const SquaredButton: FC<SquaredButtonProps> = ({
  onClick,
  icon,
  alt,
  orientation,
  iconWidth,
  iconHeight,
  rotateIcon
}) => {
  const borderRadiusOrientation = useMemo(() => {
    if (orientation === 'top') return '10px 10px 0px 0px';
    if (orientation === 'bottom') return '0px 0px 10px 10px';
    if (orientation === 'left') return '10px 0px 0px 10px';
    if (orientation === 'right') return '0px 10px 10px 0px';
  }, [orientation]);
  return (
    <div
      onClick={onClick}
      className="squared-button"
      style={{ borderRadius: borderRadiusOrientation }}>
      <SimpleSVGComponent
        width={iconWidth ? iconWidth : 'auto'}
        height={iconHeight ? iconHeight : 'auto'}
        icon={icon}
        alt={alt}
        rotate={rotateIcon ? rotateIcon : 'none'}
      />
    </div>
  );
};

export default SquaredButton;
