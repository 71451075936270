export type KSCSliderState = {
  kickValue: number;
  snareValue: number;
  cymbalValue: number;
};

export const KSCSliderStateInitialValue: KSCSliderState = {
  kickValue: 2,
  snareValue: 0,
  cymbalValue: 0,
};
