import React, { useRef, useEffect } from "react";

interface Props {
  audioBuffer: any;
  width: any;
  height: any;
  color: string;
}

function WaveForm({ audioBuffer, width, height, color }: Props) {
  const canvasRef = useRef<any>(null);

  useEffect(() => {
    if (!audioBuffer) return;

    const canvas = canvasRef.current;
    const canvasCtx = canvas.getContext("2d");
    const bufferLength = audioBuffer.length;
    const sliceWidth = (width * 1.0) / bufferLength;
    let x = 0;

    canvasCtx.clearRect(0, 0, width, height);
    canvasCtx.fillStyle = "#1A191E";
    canvasCtx.fillRect(0, 0, width, height);

    canvasCtx.beginPath();
    canvasCtx.strokeStyle = color;
    canvasCtx.lineWidth = 3;

    for (let i = 0; i < bufferLength; i++) {
      const y = (audioBuffer.getChannelData(0)[i] + 1) * height * 0.5;
      if (i === 0) {
        canvasCtx.moveTo(x, y);
      } else {
        canvasCtx.lineTo(x, y);
      }
      x += sliceWidth;
    }
    canvasCtx.stroke();

    // Draw lower line
    canvasCtx.beginPath();
    canvasCtx.strokeStyle = "white";
    canvasCtx.lineWidth = 0.4;
    canvasCtx.moveTo(0, height * 0.25);
    canvasCtx.lineTo(width, height * 0.25);
    canvasCtx.stroke();

    // Draw the upper line
    canvasCtx.beginPath();
    canvasCtx.strokeStyle = "white";
    canvasCtx.lineWidth = 0.4;
    canvasCtx.moveTo(0, height * 0.75);
    canvasCtx.lineTo(width, height * 0.75);
    canvasCtx.stroke();

    // Draw the middle line
    canvasCtx.beginPath();
    canvasCtx.strokeStyle = "white";
    canvasCtx.lineWidth = 1;
    canvasCtx.moveTo(0, height * 0.5);
    canvasCtx.lineTo(width, height * 0.5);
    canvasCtx.stroke();
  }, [audioBuffer, width, height, color]);

  return (
    <canvas
      ref={canvasRef}
      width={width}
      height={height}
      style={{ width: "100%", height: "100%", borderRadius: "10px 10px 0 0" }}
    />
  );
}

export default WaveForm;
