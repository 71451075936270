import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import './MobileLandscapeContainer.css';
import { theme } from '../../constants/theme';
import WaveFormContainer from '../WaveForm/WaveFormContainer';
import { AudioSamplesFromServerState } from '../../types/AudioSampleState';
import { WorkSpaceBoxState, WorkSpacesState } from '../../types/WorkSpace';
import VariationContainer from '../VariationContainer/VariationContainer';
import { VariationState } from '../../types/VariationState';
import GenerateButton from '../Buttons/GenerateButton';
import WorkSpaces from '../WorkSpaces/WorkSpaces';
import VerticalSliderContainer from '../VerticalSlider/VerticalSliderContainer';
import { KSCSliderState } from '../../types/KSCSliderState';
import SelectAudioContainer from '../SelectAudio/SelectAudioContainer';
import OneDVariationContainer from '../VariationContainer/OneDVariationContainer';
import OneDVariationContainerMobile from '../VariationContainer/OneDVariationContainerMobile';

interface MobileLandscapeContainerProps {
  audioSamplesFromServerState: AudioSamplesFromServerState;
  currentWorkspaceSelected: WorkSpaceBoxState;
  onRadarChartDragEnd: (index: number, value: number) => void;
  variationState: VariationState;
  workspaceBox: WorkSpaceBoxState;
  onClickOnGenerateButton: () => void;
  onClickOnSampleHistoryButtons: (mode: string) => void;
  workSpacesState: WorkSpacesState;
  onClickOnWorkSPaceBox: (index: number) => void;
  setWorkSpaceState: (value: React.SetStateAction<WorkSpacesState>) => void;
  onChange: (value: number, type: 'Kick' | 'Snare' | 'Cymbal' | 'Variation', setTheRestToZero: boolean) => void;
  KSCSliderState: KSCSliderState;
  setKSCSliderState: (value: React.SetStateAction<KSCSliderState>) => void;
  onOneDVariationSliderChange: (x: number) => void;
  onClickOnDirectionVariationButton: () => void;
  setVariationState: (value: React.SetStateAction<VariationState>) => void;
  onDroppedAnAudioFile: (file: any) => void;
}

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const MobileLandscapeContainer: FC<MobileLandscapeContainerProps> = ({
  audioSamplesFromServerState,
  currentWorkspaceSelected,
  onRadarChartDragEnd,
  variationState,
  workspaceBox,
  onClickOnGenerateButton,
  onClickOnSampleHistoryButtons,
  workSpacesState,
  onClickOnWorkSPaceBox,
  setWorkSpaceState,
  onChange,
  KSCSliderState,
  setKSCSliderState,
  onOneDVariationSliderChange,
  onClickOnDirectionVariationButton,
  setVariationState,
  onDroppedAnAudioFile
}) => {
  return (
    <div className="mobile-landscape-main-container">
      <div className="mobile-landscape-explore-waveform-and-sliders-container">
        <div className="mobile-landscape-top-left-container">
          <div className="mobile-landscape-three-sliders-geenrate-variation">
            <SelectAudioContainer
              onDroppedAnAudioFile={onDroppedAnAudioFile}
            />
                <div className="mobile-landscape-sliders-container">
                  <VerticalSliderContainer
                    onChange={onChange}
                    type="Kick"
                    value={KSCSliderState.kickValue}
                    setKSCSliderState={setKSCSliderState}
                  />
                  <VerticalSliderContainer
                    onChange={onChange}
                    type="Snare"
                    value={KSCSliderState.snareValue}
                    setKSCSliderState={setKSCSliderState}
                  />
                  <VerticalSliderContainer
                    onChange={onChange}
                    type="Cymbal"
                    value={KSCSliderState.cymbalValue}
                    setKSCSliderState={setKSCSliderState}
                  />
              </div>
          </div>
          <div className='mobile-landscape-variation-container'>
            <OneDVariationContainerMobile
              value={variationState.variationValue.x}
              onOneDVariationSliderChange={onOneDVariationSliderChange}
              onClickOnDirectionVariationButton={onClickOnDirectionVariationButton}
              setVariationState={setVariationState}
            />
          </div>
      </div>
      <div className='mobile-landscape-right-side'>
          <GenerateButton
            onClick={onClickOnGenerateButton}
            onClickOnSampleHistoryButtons={onClickOnSampleHistoryButtons}
          />
        <div className='mobile-landscape-explore-waveform'>
          <div className="mobile-landscape-waveform-container">
            <WaveFormContainer
              audioSampleState={audioSamplesFromServerState}
              workspaceBox={currentWorkspaceSelected}
            />
          </div>
          <div className="mobile-landscape-explore-container">
            <VariationContainer
              audioData={audioSamplesFromServerState}
              onRadarChartDragEnd={onRadarChartDragEnd}
              variationState={variationState}
              workspaceBox={workspaceBox}
            />
          </div>
        </div>
        </div>
      </div>
      <WorkSpaces
        workSpacesState={workSpacesState}
        onClickOnWorkSPaceBox={onClickOnWorkSPaceBox}
        setWorkSpaceState={setWorkSpaceState}
      />
    </div>
  );
};

export default MobileLandscapeContainer;
