import "./LoadingGIFWithText.css";
import PropTypes from "prop-types";
import loadingGIF from "../../assets/DrumGan_ANIM_V2_GIF_Alpha.gif";

LoadingGIFWithText.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  text: PropTypes.string,
};

interface Props {
  width?: string;
  height?: string;
  text?: string;
}

export default function LoadingGIFWithText({ width, height, text }: Props) {
  return (
    <>
      {text && <div className="processing-text">{text}</div>}
      <img
        style={{
          width: width ? width : "100%",
          height: height ? height : "100%",
        }}
        src={loadingGIF}
        alt="loading-gif"
      />
    </>
  );
}
