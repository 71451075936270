import { FC, useCallback } from 'react';
import { WorkSpacesState } from '../../types/WorkSpace';
import './WorkSpaces.css';
import WorkSpaceBox from './WorkSpaceBox';
import KeyboardIcon from '@mui/icons-material/Keyboard';
import RoundedButtonWithLogo from '../Buttons/RoundedButtonWithLogo';
import downloadIcon from '../../assets/download-icon.svg';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { motion } from 'framer-motion';
import { isMobile } from 'react-device-detect';

interface WorkSpacesProps {
  workSpacesState: WorkSpacesState;
  onClickOnWorkSPaceBox: (index: number) => void;
  setWorkSpaceState: (value: React.SetStateAction<WorkSpacesState>) => void;
}

const WorkSpaces: FC<WorkSpacesProps> = ({
  workSpacesState,
  onClickOnWorkSPaceBox,
  setWorkSpaceState
}) => {
  const onClickOnDownload = useCallback(() => {
    const zip = new JSZip();
    workSpacesState.boxes.map((box) => {
      if (box.sample) {
        const filename: string = box.sample.filename;
        const data = box.sample.blob;
        zip.file(filename, data);
      }
      return 0;
    });
    zip.generateAsync({ type: 'blob' }).then((content) => {
      saveAs(content, 'DrumGAN.zip');
    });
  }, [workSpacesState]);

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  return (
    <div className="workspaces-border-container">
      <div className="workspaces-main-container">
        <div className="workspaces-top-container">
          <div className="workspaces-type-container"> Workspaces</div>
          {isMobile && (
              <div className="download-button-container">
                <RoundedButtonWithLogo
                  icon={downloadIcon}
                  onClick={() => onClickOnDownload()}
                  disabled={false}
                  />
              </div>
          )}
        </div>
        <motion.div
          animate="show"
          initial="hidden"
          variants={container}
          className="workspaces-bottom-container">
          {workSpacesState.boxes.map((data) => (
            <WorkSpaceBox
              workSpaceBoxState={data}
              onClick={onClickOnWorkSPaceBox}
              key={data.index}
              keyBoardLanguage={workSpacesState.keyBoardLanguage}
            />
          ))}
        </motion.div>
        {!isMobile && (
          <div className="workspaces-buttons-container">
            <div className="keyboard-container">
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}>
                <KeyboardIcon />
              </div>
              <div style={{ display: 'flex' }}>
                <div
                  style={{
                    color: workSpacesState.keyBoardLanguage === 'French' ? '#625f6d' : 'white',
                    cursor: 'pointer'
                  }}
                  onClick={() =>
                    setWorkSpaceState((data) => {
                      return { ...data, keyBoardLanguage: 'English' };
                    })
                  }>
                  EN
                </div>
                <span style={{ color: 'white' }}>&nbsp;&nbsp;|&nbsp;&nbsp; </span>
                <div
                  style={{
                    color: workSpacesState.keyBoardLanguage === 'French' ? 'white' : '#625f6d',
                    cursor: 'pointer'
                  }}
                  onClick={() =>
                    setWorkSpaceState((data) => {
                      return { ...data, keyBoardLanguage: 'French' };
                    })
                  }>
                  FR
                </div>
              </div>
            </div>
            <div className="download-button-container">
              <RoundedButtonWithLogo
                icon={downloadIcon}
                onClick={() => onClickOnDownload()}
                disabled={false}
                />
            </div>
          </div>
            )}
      </div>
    </div>
  );
};

export default WorkSpaces;
