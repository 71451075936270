import { FC, useMemo } from "react";

import kickSVG from "../../assets/kick.svg";
import snareSVG from "../../assets/snare.svg";
import cymbalSVG from "../../assets/crash.svg";
import "./HorizontalSliderContainer.css";
import { theme } from "../../constants/theme";

import { KSCSliderState } from "../../types/KSCSliderState";

import SimpleSVGComponent from "../UI/SimpleSVGComponent";
import { VariationState } from "../../types/VariationState";
import VerticalSlider from "../VerticalSlider/VerticalSlider";
import HorizontalSlider from "./HorizontalSlider";

interface RotarySliderContainerProps {
  onChange: (
        value: number,
        type: ("Kick" | "Snare" | "Cymbal" | "Variation"),
        setTheRestToZero: boolean
      ) => void;
  type: "Kick" | "Snare" | "Cymbal" | "Variation";
  value: number;
  setKSCSliderState?: (value: React.SetStateAction<KSCSliderState>) => void;
  setVariationSliderState?: (
    value: React.SetStateAction<VariationState>
  ) => void;
  onChangeVariation?: (value: number)=> void;
}

const RotarySliderContainer: FC<RotarySliderContainerProps> = ({
  onChange,
  type,
  value,
  setKSCSliderState,
  setVariationSliderState,
  onChangeVariation
}) => {
  const currentIcon = useMemo(() => {
    switch (type) {
      case "Kick":
        return kickSVG;
      case "Snare":
        return snareSVG;
      case "Cymbal":
        return cymbalSVG;
    }
  }, [type]);

  const currentColor = useMemo(() => {
    switch (type) {
      case "Kick":
        return theme.palette.green;
      case "Snare":
        return theme.palette.purple;
      case "Cymbal":
        return theme.palette.red;
      case "Variation":
        return theme.palette.lightGrey;
    }
  }, [type]);

  const onChangeSlider = (value: number) => {
    if (setKSCSliderState) {
      switch (type) {
        case "Kick":
          setKSCSliderState((data) => {
            return { ...data, kickValue: value };
          });
          break;
        case "Snare":
          setKSCSliderState((data) => {
            return { ...data, snareValue: value };
          });
          break;
        case "Cymbal":
          setKSCSliderState((data) => {
            return { ...data, cymbalValue: value };
          });
          break;
      }
    } else if (setVariationSliderState && type == "Variation") {
      setVariationSliderState((data) => {
        return { ...data, variationValue: { x: value, y: 0 } };
      });
    }
  };

  const onClickOnSliderType = () => {
    if (setKSCSliderState) {
      onChange(2, type, true);
      switch (type) {
        case "Kick":
          setKSCSliderState((data) => {
            return { ...data, kickValue: 2, snareValue: 0, cymbalValue: 0 };
          });
          break;
        case "Snare":
          setKSCSliderState((data) => {
            return { ...data, kickValue: 0, snareValue: 2, cymbalValue: 0 };
          });
          break;
        case "Cymbal":
          setKSCSliderState((data) => {
            return { ...data, kickValue: 0, snareValue: 0, cymbalValue: 2 };
          });
          break;
      }
    } else if (setVariationSliderState && type == "Variation") {
      setVariationSliderState((data) => {
        return { ...data };
      });
  }};
  const onChangeSliderEnd = (newValue: number) => {
    if (onChangeVariation) {
      onChangeVariation(newValue);
    } else {
      onChange(newValue, type, false);
    }
  };
  return (
    <div className="horizontal-slider-container-border-container">
      <div className="horizontal-slider-container-main-container">
        {/* <div className="Horizontal-slider-container-top-part">
          <div
            className="Horizontal-slider-container-type-container"
            style={{ backgroundColor: currentColor }}
            onClick={onClickOnSliderType}
          >
            {type !== "Variation" && <SimpleSVGComponent
              width="1.2rem"
              height="240%"
              icon={currentIcon}
              alt="drum-icon"
            />}
            {type}
          </div>
        </div> */}
        <div className="variation-container-type-container">VARIATION</div>
        <div className="horizontal-slider-container-bottom-part">
          <HorizontalSlider
            value={value}
            onChange={onChangeSlider}
            onChangeEnd={onChangeSliderEnd}
            type={type}
            size={1}
            showGauge={true}
            showHand={true}
          />
        </div>
      </div>
    </div>
  );
};

export default RotarySliderContainer;
