import { FC, useState } from 'react';
import { VariationState } from '../../types/VariationState';
import './OneDVariationContainerMobile.css';
import changeIcon from "../../assets/change-icon.svg";
import SimpleSVGComponent from '../UI/SimpleSVGComponent';
import VerticalSliderContainer from '../VerticalSlider/VerticalSliderContainer';
import VerticalSlider from '../VerticalSlider/VerticalSlider';
import HorizontalSliderContainer from '../HorizontalSlider/HorizontalSliderContainer';

interface OneDVariationContainerMobileProps {
  value: number;
  onOneDVariationSliderChange: (x: number) => void;
  onClickOnDirectionVariationButton: () => void;
  setVariationState: (value: React.SetStateAction<VariationState>) => void;
}

const OneDVariationContainerMobile: FC<OneDVariationContainerMobileProps> = ({
  value,
  onOneDVariationSliderChange,
  onClickOnDirectionVariationButton,
  setVariationState
}) => {
  const [sliderState, setSliderState] = useState(0);
  const onChange = () => {

  }
  return (
    <div className="mobile-one-d-variation-border-container">
      <div className="mobile-one-d-variation-main-container">
        <div className='mobile-one-d-variation-slider'>
          <HorizontalSliderContainer
            onChange={onOneDVariationSliderChange}
            type="Variation"
            value={value}
            setVariationSliderState={setVariationState}
          />
          <div className='mobile-one-d-variation-text'>Intensity</div>
          </div>
          {/* <CustomSlider onChange={onSliderChange} value={value} /> */}

          <div className="mobile-one-d-variation-bottom-right-container">
            <div className="one-d-variation-squared-button-border">
              <div
                onClick={() => onClickOnDirectionVariationButton()}
                className="one-d-variation-squared-button"
              >
                <SimpleSVGComponent
                  width="2rem"
                  height="auto"
                  alt="change-icon"
                  icon={changeIcon}
                />
                NEW
              </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OneDVariationContainerMobile;
