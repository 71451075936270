import SimpleSVGComponent from "../UI/SimpleSVGComponent";
import "./Header.css";
import DrumGANLogo from "../../assets/Logo_DrumGan.svg";
import LoadingGIFWithText from "../LoadingGIF/LoadingGIFWithText";
import { AudioSamplesFromServerState } from "../../types/AudioSampleState";
import { FC, useMemo, useState } from "react";
import DrumGANLogoName from "../../assets/LogoDrumGANName.svg";
import BigRoundedButton from "../Buttons/BigRoundedButton";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import HelperTextContainer from "../UI/HelperTextContainer";
import enterIcon from "../../assets/enter-icon.svg";
import backspaceIcon from "../../assets/backspace-icon.svg";
import spacebarIcon from "../../assets/spacebar-icon.svg";
import arrowsIcon from "../../assets/arrows-icon.svg";
import kLetterIcon from "../../assets/k-letter-icon.svg";
import lLetterIcon from "../../assets/l-letter-icon.svg";
import mLetterIcon from "../../assets/m-letter-icon.svg";
import sonyLogo from "../../assets/sony-logo.svg";
import { isMobile } from "react-device-detect";

interface HeaderProps {
  audioSamplesState: AudioSamplesFromServerState;
}

export const Header: FC<HeaderProps> = ({ audioSamplesState }) => {
  const [isHelperTextOpen, setIsHelperTextOpen] = useState(false);
  const helperText = useMemo(() => {
    return (
      <div className="helper-text-main-container">
        <div className="helper-text-up-container">
          <strong>Generate</strong> & let DrumGAN synthesize high-fidelity audio
          for a wide variety of kick, snare, and cymbal sounds.
          <br />
          <strong>Choose a workspace</strong> to save your generated sounds and{" "}
          <strong>explore</strong> the endless variations of sounds.
        </div>
        <div className="helper-text-down-container">
          <strong>KeyBoard Shortcuts</strong>
          <div style={{ display: "flex" }}>
            <div className="helper-text-down-box-container">
              <div className="helper-text-keyboard-container">
                <SimpleSVGComponent
                  width="2rem"
                  alt="enter-icon"
                  icon={enterIcon}
                />
                Generate
              </div>
              <div className="helper-text-keyboard-container">
                <SimpleSVGComponent
                  width="3.5rem"
                  alt="enter-icon"
                  icon={backspaceIcon}
                />
                Back to last sample
              </div>
              <div className="helper-text-keyboard-container">
                <SimpleSVGComponent
                  width="3.5rem"
                  alt="enter-icon"
                  icon={spacebarIcon}
                />
                Play sample
              </div>
              <div className="helper-text-keyboard-container">
                <SimpleSVGComponent
                  width="2rem"
                  alt="enter-icon"
                  icon={arrowsIcon}
                />
                Navigate through workspaces
              </div>
            </div>
            <div className="helper-text-down-box-container">
              <div className="helper-text-keyboard-container">
                <SimpleSVGComponent
                  width="2rem"
                  alt="enter-icon"
                  icon={kLetterIcon}
                />
                Generate kick
              </div>
              <div className="helper-text-keyboard-container">
                <SimpleSVGComponent
                  width="2rem"
                  alt="enter-icon"
                  icon={lLetterIcon}
                />
                Generate snare
              </div>
              <div className="helper-text-keyboard-container">
                <SimpleSVGComponent
                  width="2rem"
                  alt="enter-icon"
                  icon={mLetterIcon}
                />
                Generate cymbal
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }, []);
  return (
    <div className="header-main-container">
      <div className="header-sony-container">
        {" "}
        <SimpleSVGComponent alt="Sony logo" width="4.5rem" icon={sonyLogo} />
      </div>
      <div className="header-drumgan-container">
        <div className="header-logo-and-name-container">
          <div className="header-logo-background">
            <SimpleSVGComponent
              alt="DrumGAN-logo"
              width={isMobile ? "40%" : "60%"}
              icon={DrumGANLogo}
            />
          </div>
          <SimpleSVGComponent
            alt="DrumGAN-logo-name"
            width={isMobile ? "100px" : "147px"}
            icon={DrumGANLogoName}
          />
          {audioSamplesState.isFetching && (
            <LoadingGIFWithText width="40px" height="40px" />
          )}
        </div>
        <BigRoundedButton
          icon={<HelpOutlineIcon />}
          text={isMobile ? "" : "HELP"}
          onClick={() => setIsHelperTextOpen((state) => !state)}
          isSvg={false}
          isLoading={false}
          height="50%"
          width={isMobile ? "3rem" : "6rem"}
        />
        {isHelperTextOpen && (
          <HelperTextContainer
            setIsOpen={setIsHelperTextOpen}
            text={helperText}
          />
        )}
      </div>
    </div>
  );
};

export default Header;
