import { Alert, Snackbar } from '@mui/material';
import axios from 'axios';
import { FC, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { normalizeSampleName } from '../../tools/stringsTools';
import './DragAndDropContainer.css';
import LoadingGIFWithText from '../LoadingGIF/LoadingGIFWithText';
import handIcon from '../../assets/hand-icon.svg';
import SimpleSVGComponent from '../UI/SimpleSVGComponent';

interface DragAndDropContainerProps {
  onDroppedAnAudioFile: (file: any) => void;
}

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const DragAndDropContainer: FC<DragAndDropContainerProps> = ({ onDroppedAnAudioFile }) => {
  const [isErrorUploadingFile, setIsErrorUploadingFile] = useState<boolean>(false);
  const [isLoadingUploadingFile, setIsLoadingUploadingFile] = useState<boolean>(false);

  const [uploadedFilename, setUploadedFilename] = useState<string>('');
  const [uploadedFilenameGivenName, setUploadedFilenameGivenName] = useState<string>('');
  const [isUploadSuccess, setIsUploadSuccess] = useState<boolean>(false);

  const onDropFile = (file: any) => {
    setIsLoadingUploadingFile(true);

    if (!file[0]) {
      setIsErrorUploadingFile(true);
      setIsLoadingUploadingFile(false);
      return;
    }

    const data = new FormData();
    data.append('file', file[0]);
    setUploadedFilename(file[0].name);
    axios
      .post(`${API_ENDPOINT}/compute-latent-space`, data)
      .then((response) => {
        setIsUploadSuccess(true);
        setIsLoadingUploadingFile(false);
        onDroppedAnAudioFile(response.data);
        setUploadedFilenameGivenName(response.data.sample_name);
      })
      .catch((error) => {
        console.log(error);
        setIsErrorUploadingFile(true);
        setIsLoadingUploadingFile(false);
      });
  };

  const { getRootProps, getInputProps, isDragActive, isDragAccept } = useDropzone({
    accept: { 'audio/wav': [] },
    onDrop: onDropFile,
    maxFiles: 1,
    noKeyboard: true
  });

  return (
    <div className="drag-and-drop-container-border-container">
      <div className="drag-and-drop-container-main-container">
        <Snackbar open={isErrorUploadingFile} onClose={() => setIsErrorUploadingFile(false)}>
          <Alert onClose={() => setIsErrorUploadingFile(false)} severity="error">
            Error while uploading file. Please try again later.
          </Alert>
        </Snackbar>
        <Snackbar open={isUploadSuccess} onClose={() => setIsUploadSuccess(false)}>
          <Alert onClose={() => setIsUploadSuccess(false)} severity="success">
            File successfully uploaded ! <br />
            Your file {uploadedFilename} is now named{' '}
            {normalizeSampleName(uploadedFilenameGivenName, true, true)}.
          </Alert>
        </Snackbar>
        <div {...getRootProps({ className: 'drag-and-drop-container' })}>
          <input {...getInputProps()} />
          {isLoadingUploadingFile ? (
            <LoadingGIFWithText width="100%" height="auto" />
          ) : (
            <>
              {(!isDragActive || isDragAccept) && (
                <SimpleSVGComponent width="22%" height="22%" icon={handIcon} alt="hand-icon" />
              )}
              {isDragActive
                ? isDragAccept
                  ? 'Dragging ...'
                  : "Can't drag this kind of file"
                : 'Drag an audio file'}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default DragAndDropContainer;
