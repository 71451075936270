import { FC } from "react";
import { AudioSamplesFromServerState } from "../../types/AudioSampleState";
import "./VariationContainer.css";
import RadarChart from "./RadarChart";
import { VariationState } from "../../types/VariationState";
import { WorkSpaceBoxState } from "../../types/WorkSpace";
import { isMobile } from "react-device-detect";

interface VariationContainerProps {
  audioData: AudioSamplesFromServerState;
  onRadarChartDragEnd: (index: number, value: number) => void;
  variationState: VariationState;
  workspaceBox: WorkSpaceBoxState;
}

const VariationContainer: FC<VariationContainerProps> = ({
  audioData,
  onRadarChartDragEnd,
  variationState,
  workspaceBox
}) => {
  return (
    <div className="variation-container-border-container">
      <div className="variation-container-main-container">
        {!isMobile && (
        <div className="variation-container-type-container">Explore</div>
      )}
        <RadarChart
          onDragEnd={onRadarChartDragEnd}
          audioSamplesFromServerState={audioData}
          variationState={variationState}
          color={workspaceBox.color}
          lowOpacityColor={workspaceBox.lowOpacityColor}
        />
      </div>
    </div>
  );
};

export default VariationContainer;
